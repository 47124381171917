import { useState } from "react";

export const useCheckInput = (initValue: boolean) => {
    const [value, setValue] = useState(initValue);
    const [changed, setChanged] = useState(false);
    const handleChange = (_: any, checked?: boolean) => {
        setValue(checked ?? false);
        setChanged(true);
    };
    const set = (val: boolean) => {
        setValue(val);
        setChanged(false);
    };

    return {
        changed,
        checked: value,
        onChange: handleChange,
        setValue: set
    };
};

