import { useState } from "react";
import {
    Checkbox, PrimaryButton, Link, MessageBar, MessageBarType, Stack, DefaultButton
} from "@fluentui/react";
import { useCheckInput } from "../hooks/useCheckInput";
import { useMainState } from "../globalState/context";
import { SET_PROCESSING, SET_SCREEN } from "../globalState/actions";

const Summary = () => {
    const { state: { termsUrl, personalDataUrl, contact, contract, processing }, dispatch } = useMainState();
    const [message, setMessage] = useState("");
    const check1 = useCheckInput(false);
    const check2 = useCheckInput(false);
    const check3 = useCheckInput(false);
    const readOnlyForm: boolean = processing ?? false;
    const approvalDate = new Date(contract?.approvalDate ?? new Date()).toLocaleDateString();

    const previousStep = () => dispatch({ type: SET_SCREEN, screen: "contactForm" });
    const formIsValid = (): boolean => { return check1.checked && check2.checked && check3.checked; };

    const downloadContract = async () => {
        const response = await fetch(`/api/contract/file?contractId=${contract?.id}`);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = response.headers.get("X-Ms-File-Name") ?? "contract.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }

    const signContract = async () => {
        dispatch({ type: SET_PROCESSING, processing: true });

        const response = await fetch("/api/contract", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                contractId: contract?.id
            })
        });

        if (!response.ok) {
            setMessage(`Došlo k neočekávané chybě. Kontaktujte prosím naši podporu na ${process.env.REACT_APP_EMAIL}`)
        }

        dispatch({ type: SET_PROCESSING, processing: false });

        if (response.ok) {
            setMessage("");
            dispatch({ type: SET_SCREEN, screen: "finish" });
        }
    };

    const getContractType = (type?: string) => {
        switch (type) {
            case "Projektová smlouva": return "Projektové";
            case "Autorská smlouva": return "Autorské";
            default: return "Konzultační";
        }
    }

    const renderContractDocument = () => (
        <div style={{ marginLeft: 4 }}>
            Se seznámil/a s obsahem {getContractType(contract?.type)} smlouvy v znění dostupném na <Link href="#contract" onClick={downloadContract}>tomto odkazu</Link>, a prohlašuji,
            že v tomto znění představuje obsah mojí skutečnou vůli. Rozumím, že zaškrtnutí tohoto políčka představuje
            podpis Smlouvy z mé strany, a chci ji takto potvrzenou a podepsanou z mé strany odeslat Objednateli.
        </div>
    );

    const renderTermsAndConditionsAgreement = () => (
        <div style={{ marginLeft: 4 }}>
            Si přečetl/a a seznámil/a se se <Link href={termsUrl} target="_blank">Smluvními podmínkami</Link> a bylo mi umožněno (i) se dotázat na jejich obsah 
            a (ii) byly mi poskytnuty potřebné odpovědi.
        </div>
    );

    const renderPersonalDataAgreement = () => (
        <div style={{ marginLeft: 4 }}>
            Si přečetl/a a seznámil/a se se <Link href={personalDataUrl} target="_blank">Zásadami zpracování osobních údajů</Link> a bylo umožněno (i) se dotázat 
            na jejich obsah a (ii) byly mi poskytnuty potřebné odpovědi.
        </div>
    );

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <div>
                Já, <strong>{contact?.firstName} {contact?.lastName}</strong> (dále jako „Dodavatel“), tímto přijímám návrh
                na uzavření {contract?.type} společnosti Fresenius Kabi s.r.o. (dále jako „Objednatel“) podepsaný ze strany Objednatele dne {approvalDate}.
            </div>

            <div>
                Já, <strong>{contact?.firstName} {contact?.lastName}</strong>, kliknutím na tlačítko <strong>ODESLAT</strong> prohlašuji, že jsem:
            </div>

            <Checkbox onRenderLabel={renderContractDocument} {...check1} disabled={readOnlyForm} />
            <Checkbox onRenderLabel={renderTermsAndConditionsAgreement} {...check2} disabled={readOnlyForm} />
            <Checkbox onRenderLabel={renderPersonalDataAgreement} {...check3} disabled={readOnlyForm} />

            <div>
                Kliknutím na tlačítko <strong>ODESLAT</strong> potvrdíte svůj souhlas s uzavřením {getContractType(contract?.type)} Smlouvy a odešlete ji Objednateli.
                Upozorňujeme, že pokud k podpisu Smlouvy z Vaší strany nedojde do 15 dní ode dne {approvalDate},
                jejím návrhem nebudeme vázáni a návrh tím zaniká, i kdyby následně došlo k podpisu návrhu Smlouvy z Vaší strany.
            </div>

            <div>
                Stejnopis Smlouvy (včetně Smluvních podmínek) Vám přijde na emailovou adresu, kterou na Vás máme uloženou.
                Doporučujeme, abyste kontroloval/a také složku nevyžádané pošty.<br />
                Pokud byste zjistil/a, že ve Vašich údajích nebo v obsahu návrhu Smlouvy je chyba, vraťte se, prosím, na 
                předchozí stránku kliknutím na tlačítko <strong>ZPĚT</strong>.<br />
                Pokud nemáte zájem Smlouvu s Dodavatelem uzavřít, stačí když zavřete okno prohlížeče.
            </div>

            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
                <DefaultButton text="Zpět" disabled={readOnlyForm} onClick={previousStep} />
                <PrimaryButton text="Odeslat" disabled={!formIsValid() || readOnlyForm} onClick={signContract} />
            </Stack>

            {message &&
                <MessageBar messageBarType={MessageBarType.warning} onDismiss={() => setMessage("")}>
                    {message}
                </MessageBar>
            }
        </Stack>
    )
}

export default Summary;
