import { Link } from "@fluentui/react";
import { useMainState } from "../globalState/context";

const ContractAuthor = () => {
    const { state: { termsUrl, contract } } = useMainState();

    if (!contract) {
        return <div />;
    }

    const renderLink = (url?: string, text?: string) => <Link href={url} target="_blank">{text}</Link>

    return (
        <ol className="noLeftPadding">
            <li>
                Dodavatel (tedy Vy) pro Objednavatele (tedy pro nás):<br />
                <ul>
                    <li>vypracuje <strong>{contract.output}</strong> zaměřenou/zaměřený na téma <strong>{contract.serviceDescription}</strong> s názvem <strong>{contract.name}</strong> (dále jen <strong>„dílo“</strong>) a odevzdá ho Objednavateli způsobem  který bude upřesněn tak jako je uvedené níže a jestli k upřesnění nedošlo, tak e-mailem na adresu <strong>{contract.contractOwnerEmail}</strong></li>
                    <li>odprezentuje <strong>{contract.output}</strong> zaměřenou na téma <strong>{contract.serviceDescription}</strong> s názvem <strong>{contract.name}</strong> (dále jen <strong>„dílo“</strong>)  na <strong>{contract.congressName}</strong>, místo konání <strong>{contract.venue}</strong> v rámci odborného programu a to dne <strong>{new Date(contract.presentationDate).toLocaleDateString()}</strong> (dále jen <strong>„Akce“</strong>) bude moderovat odborný program na <strong>{contract.congressName}</strong>, místo konání <strong>{contract.venue}</strong> v rámci odborného programu a to dne <strong>{new Date(contract.presentationDate).toLocaleDateString()}</strong> (dále jen <strong>„Akce“</strong>)</li>
                    <li>v souladu s touto <strong>Smlouvou</strong> a se {renderLink(termsUrl, "Smluvními podmínkami")} Objednavatele (dále jen jako <strong>„SP“</strong>) se Dodavatel zavazuje dílo vytvořit osobně, v dohodnutém rozsahu a čase a poskytnout k němu licenci v rozsahu stanoveném v SP. Rozsah díla, termín jeho dodání, i jeho další specifické vlastnosti může Objednavatel upřesnit do 10 dní od uzavření Smlouvy. Pokud ze strany Objednavatele nedojde k upřesnění termínu dodání, zavazuje se Dodavatel dílo dodat do 10 dní od výzvy Objednavatele k jeho dodání.</li>
                </ul>
            </li>
            <li>
                Objednavatel (tedy my) se zavazuje Dodavateli (tedy Vám):<br />
                <ul>
                    <li>zaplatit Odměnu ve výši <strong>{contract.contractPrice}</strong>, přičemž Odměna zahrnuje i odměnu Dodavatele za poskytnutí licence a poskytnutí všech práv s tím souvisejících, jako i náhradu nákladů spojených se zhotovením a převedením díla, pokud níže není stanovené jinak,</li>
                    <li>
                        uhradit náklady související s plněním jeho závazku (pokud by k tomu mělo dojít na odborné Akci specifikované v bodě 1 a pokud se tak Smluvní strany dohodly), a to v rozsahu:<br />
                        <ul>
                            <li>cestovní náklady na Akci ve výši: <strong>{contract.transportationCosts}</strong></li>
                            <li>registrační poplatek na Akci ve výši: <strong>{contract.registrationCosts}</strong></li>
                            <li>náklady ubytování související s Akcí ve výši: <strong>{contract.accomodationCosts}</strong></li>
                            <li>náklady na stravu související s Akcí ve výši: <strong>{contract.foodCosts}</strong></li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    )
}

export default ContractAuthor;
