import { Link } from "@fluentui/react";
import { useMainState } from "../globalState/context";

const ContractProject = () => {
    const { state: { termsUrl, contract } } = useMainState();

    if (!contract) {
        return <div />;
    }

    const renderLink = (url?: string, text?: string) => <Link href={url} target="_blank">{text}</Link>

    return (
        <ol className="noLeftPadding">
            <li>
                Dodavatel (tedy Vy) pro Objednavatele (tedy pro nás):<br />
                <ul>
                    <li>poskytne služby týkající se: <strong>{contract.serviceDescription}</strong> (dále jen <strong>„Služby“</strong>). Služby jsou co do rozsahu, ceny, jakož i práv a povinností Stran specifikované v Příloze č. 1 Smlouvy, která je neoddělitelnou součástí Smlouvy,</li>
                    <li>se v souladu s touto <strong>Smlouvou</strong> a se {renderLink(termsUrl, "Smluvními podmínkami")} Objednavatele (dále jen <strong>„SP“</strong>) zavazuje poskytnout Služby osobně, v dohodnutém rozsahu, způsobem a čase. Rozsah Služeb, jako i další specifické požadavky týkající se Služeb může Objednavatel upřesnit ve lhůtě 10 dní od uzavření Smlouvy.</li>
                </ul>
            </li>
            <li>
                Objednavatel (tedy my) se zavazuje Dodavateli (tedy Vám):<br />
                <ul>
                    <li>zaplatit Odměnu ve výši specifikované v Příloze č. 1 této Smlouvy</li>
                </ul>
            </li>
        </ol>
    )
}

export default ContractProject;
