import { Link } from "@fluentui/react";
import { useMainState } from "../globalState/context";

const ContractConsultation = () => {
    const { state: { termsUrl, contract } } = useMainState();

    if (!contract) {
        return <div />;
    }

    const renderLink = (url?: string, text?: string) => <Link href={url} target="_blank">{text}</Link>

    return (
        <ol className="noLeftPadding">
            <li>
                Dodavatel (tedy Vy) pro Objednavatele (tedy pro nás):<br />
                <ul>
                    <li>poskytne konzultační služby týkající se: <strong>{contract.serviceDescription}</strong> (dále jen <strong>„Služby“</strong>). Není-li termín dodání Služby definována v předchozí větě, Dodavatel svůj závazek splní do 10 dní od doručení výzvy Objednavatele k dodání Služby,</li>
                    <li>se v souladu s touto <strong>Smlouvou</strong> a se {renderLink(termsUrl, "Smluvními podmínkami")} Objednavatele (smluvní podmínky spolu dále jen <strong>„SP“</strong>) zavazuje poskytnout Služby osobně, v dohodnutém rozsahu, způsobem a čase. Rozsahu Služeb, jako i další specifické požadavky týkající se Služeb může Objednavatel upřesnit ve lhůtě 10 dní od uzavření Smlouvy.</li>
                </ul>
            </li>
            <li>
                Objednavatel (tedy my) se zavazuje Dodavateli (tedy Vám):<br />
                <ul>
                    <li>zaplatit Odměnu ve výši <strong>{contract.contractPrice}</strong>, přičemž platí, že náhrada všech dalších nákladů Dodavatele je již zahrnuta v Odměně, pokud není níže uvedené jinak,</li>
                    <li>
                        pokud by k plnění závazku Dodavatele mělo dojít mimo jeho pracoviště, Objednavatel uhradí Dodavateli náklady související s plněním jeho závazku, a to v rozsahu:<br />
                        <ul>
                            <li>cestovní náklady do místa plnění Smlouvy ve výši: <strong>{contract.transportationCosts}</strong></li>
                            <li>registrační poplatek, bude-li Služba poskytována na Akci, u které bude účast spojena s úhradou takového poplatku, ve výši: <strong>{contract.registrationCosts}</strong></li>
                            <li>náklady ubytování související poskytnutím Služby ve výši: <strong>{contract.accomodationCosts}</strong></li>
                            <li>náklady na stravu související s Akcí ve výši: <strong>{contract.foodCosts}</strong></li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ol>
    )
}

export default ContractConsultation;
