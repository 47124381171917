import { useEffect, useState } from "react";
import { MessageBar, Stack, TextField, PrimaryButton, MessageBarType } from "@fluentui/react";
import { useMainState } from "../globalState/context";
import { SET_CONTACT, SET_CONTRACT_ID, SET_PROCESSING, SET_SCREEN } from "../globalState/actions";

const ValidateToken = () => {
    const { state: { contractId, processing }, dispatch } = useMainState();
    const [message, setMessage] = useState("");
    const [token, setToken] = useState<string>("");
    const [invalid, setInvalid] = useState<boolean>(false);

    useEffect(() => {
        const url = new URLSearchParams(window.location.search);
        const id = url.get("contractId");
        if (id) {
            dispatch({ type: SET_CONTRACT_ID, contractId: id })
        } else {
            setInvalid(true);
        }
    }, [dispatch]);

    useEffect(() => {
        if (!contractId) { return; }
        const requestToken = async () => {
            const response = await fetch(`/api/token/request?contractId=${contractId}`);
            if (!response.ok) {
                setInvalid(true);
            }
        }

        requestToken();
    }, [contractId]);

    const validateToken = async () => {
        dispatch({ type: SET_PROCESSING, processing: true });
        setMessage("");
        const response = await fetch("/api/token/validate", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ contractId, token })
        });

        if (response.ok) {
            const contact = await response.json();
            setMessage("");
            dispatch({ type: SET_CONTACT, contact: contact });
            dispatch({ type: SET_PROCESSING, processing: false });
            dispatch({ type: SET_SCREEN, screen: "contract" });
        } else {
            dispatch({ type: SET_PROCESSING, processing: false });
            if (response.status === 401) {
                setMessage("Ověřovací kód není platný.")
            } else {
                setMessage(`Došlo k neočekávané chybě. Kontaktujte prosím naši podporu na ${process.env.REACT_APP_EMAIL}`)
            }
        }
    };

    const tokenChanged = (_: any, value?: string) => {
        setToken(value?.trim() ?? "");
    }

    const textFieldKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            validateToken();
        }
    }

    if (invalid) {
        return (
            <MessageBar messageBarType={MessageBarType.warning}>
                Požadovaná smlouva nebyla v systému nalezena. Kontaktujte prosím naši podporu na {process.env.REACT_APP_EMAIL}
            </MessageBar>
        )
    }

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                    <TextField
                        placeholder="Vložte ověřovací kód"
                        onChange={tokenChanged}
                        value={token}
                        disabled={processing}
                        autoComplete="off"
                        autoFocus
                        onKeyUp={textFieldKeyUp}
                    />
                </Stack.Item>
                <PrimaryButton text="Potvrďte kód" disabled={processing || token?.length === 0} onClick={validateToken} />
            </Stack>
            <MessageBar messageBarType={MessageBarType.info}>
                Na váš e-mail jsme zaslali ověřovací kód.
            </MessageBar>
            {message && <MessageBar messageBarType={MessageBarType.warning} onDismiss={() => setMessage("")}>{message}</MessageBar>}
        </Stack>
    )
}

export default ValidateToken;
