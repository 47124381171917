import { Stack } from "@fluentui/react";
import logo from "../images/logo.jpg";

const Header = () => {
    return (
        <Stack>
            <Stack horizontal={true} verticalAlign="center" tokens={{ childrenGap: 30 }}>
                <div>
                    <img src={logo} alt="Fresenius Kabi Logo" height={40} />
                </div>
                <div style={{ color: "#0063be", fontSize: 24 }}>Smlouva o dílo</div>
            </Stack>
        </Stack>
    )
}

export default Header;
