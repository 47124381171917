import IAction from "../interfaces/IAction";
import IGlobalState from "../interfaces/IGlobalState";
import {
    SET_PROCESSING, SET_SCREEN, SET_CONTACT, SET_CONTRACT, SET_CONTRACT_ID, SET_TERMS_URL, SET_PERSONALDATA_URL
} from "./actions";

const saveState = (state: IGlobalState) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem("state", serializedState);
    } catch (e) { }
};

export const reducer = (state: IGlobalState, action: IAction) => {
    switch (action.type) {
        case SET_SCREEN: {
            const updatedState = { ...state, screen: action.screen };
            saveState(updatedState);
            return updatedState;
        };
        case SET_PROCESSING: return { ...state, processing: action.processing };
        case SET_CONTACT: return { ...state, contact: action.contact };
        case SET_CONTRACT: return { ...state, contract: action.contract };
        case SET_CONTRACT_ID: return { ...state, contractId: action.contractId };
        case SET_TERMS_URL: return { ...state, termsUrl: action.termsUrl };
        case SET_PERSONALDATA_URL: return { ...state, personalDataUrl: action.personalDataUrl };
        default: return state;
    }
};
