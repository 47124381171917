import React, { useContext, useReducer } from "react";
import IContext from "../interfaces/IContext";
import { reducer } from "./reducers";

const MainContext = React.createContext<IContext | null>(null);

const state = sessionStorage.getItem("state");
const initialState = state ? JSON.parse(state) : { screen: "validation" };

export const MainProvider = ({ children }: { children: any }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const contextValue: any = {
        state,
        dispatch
    };

    return (
        <MainContext.Provider value={contextValue}>
            {children}
        </MainContext.Provider>
    )
}

export const useMainState = () => {
    const context = useContext(MainContext);
    if (!context) {
        throw new Error("useMainState must be used within a MainProvider. Wrap a parent component in <MainProvider> to fix this error.");
    }
    return context;
};
