import { useEffect, useState } from "react";
import { MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { useMainState } from "../globalState/context";
import { SET_CONTRACT, SET_PERSONALDATA_URL, SET_PROCESSING, SET_SCREEN, SET_TERMS_URL } from "../globalState/actions";
import ContractAuthor from "./ContractAuthor";
import ContractConsultation from "./ContractConsultation";
import ContractProject from "./ContractProject";
import IConfig from "../interfaces/IConfig";

const Contract = () => {
    const { state: { contractId, contract, contact, processing }, dispatch } = useMainState();
    const [message, setMessage] = useState("");

    useEffect(() => {
        const loadData = async () => {
            if (!contractId || contract) { return; }

            dispatch({ type: SET_PROCESSING, processing: true });
            const response = await fetch(`/api/contract?contractId=${contractId}`);
            if (!response.ok) {
                if (response.status === 404) {
                    setMessage("Požadovaná smlouva nebyla v systému nalezena.");
                } else {
                    setMessage(`Došlo k neočekávané chybě. Kontaktujte prosím naši podporu na ${process.env.REACT_APP_EMAIL}`)
                }
                return;
            }

            const json = await response.json();
            dispatch({ type: SET_CONTRACT, contract: json });
            dispatch({ type: SET_PROCESSING, processing: false });

            const cfgResponse = await fetch(`/api/config?language=${contact?.language}`);
            const config: IConfig = await cfgResponse.json()
            dispatch({ type: SET_TERMS_URL, termsUrl: config.termsUrl });
            dispatch({ type: SET_PERSONALDATA_URL, personalDataUrl: config.personalDataUrl });
        }

        loadData();
    }, []);

    const nextStep = () => dispatch({ type: SET_SCREEN, screen: "contactForm" });

    const getContractType = (type?: string) => {
        switch (type) {
            case "Projektová smlouva": return "Projektovou";
            case "Autorská smlouva": return "Autorskou";
            default: return "Konzultační";
        }
    }

    if (message) {
        return <MessageBar messageBarType={MessageBarType.warning}>{message}</MessageBar>
    }

    if (processing) {
        return <Spinner size={SpinnerSize.large} label="Načítám smlouvu..." />
    }

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <div>
                V dalším textu znamená „my“, „nás“, „naši“ nebo „Objednavatel“ společnost Fresenius Kabi s.r.o., se sídlem
                Na strži 1702/65, Nusle, 140 00 Praha 4, IČO: 25135228, zapsanou v obchodním rejstříku pod spis. zn. C 52618
                vedenou u Městského soudu v Praze.
            </div>

            <div>
                Rádi bychom s Vámi jako Dodavatelem uzavřeli {getContractType(contract?.type)} smlouvu (dále jen „Smlouva“) s níže <strong>uvedeným předmětem</strong>:
            </div>

            {contract?.typeInternal === 733650004 && <ContractAuthor />}
            {contract?.typeInternal === 733650005 && <ContractConsultation />}
            {contract?.typeInternal === 733650006 && <ContractProject />}

            <div>
                Kliknutím na tlačítko POKRAČOVAT přejdete ke kontrole Vašich údajů, které budou uvedené v textu Smlouvy.
                Tyto údaje musí být uvedeny správně, věnujte jim proto, prosím, patřičnou pozornost. Pokud nemáte zájem Smlouvu s
                námi uzavřít, stačí když zavřete okno prohlížeče.
            </div>

            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
                <PrimaryButton text="Pokračovat" onClick={nextStep} />
            </Stack>
        </Stack>
    )
}

export default Contract;
