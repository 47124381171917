import { Stack } from "@fluentui/react";
import { useMainState } from "../globalState/context";

const Finish = () => {
    const { state: { contract } } = useMainState();
    const approvalDate = new Date(contract?.approvalDate ?? new Date()).toLocaleDateString();

    const getContractType = (type?: string) => {
        switch (type) {
            case "Projektová smlouva": return "Projektová";
            case "Autorská smlouva": return "Autorská";
            default: return "Konzultační";
        }
    }

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <div>
                Vámi podepsaná {getContractType(contract?.type)} smlouva byla právě odeslán společnosti Fresenius Kabi s.r.o. 
                Tímto je Smlouva  mezi námi jako Objednavatelem a Vámi jako Dodavatelem uzavřená, ledaže k podpisu návrhu 
                Smlouvy z Vaší strany nedošlo do 15 dní ode dne {approvalDate} - v takovém případě jejím návrhem nejsme vázáni 
                a návrh tím zanikl, i když následně došlo k podpisu návrhu Smlouvy z Vaší strany.
            </div>
            <div>
                Stejnopis podepsané Smlouvy najdete ve své emailové schránce. Doporučujeme, abyste kontroloval/a také složku nevyžádané pošty. 
            </div>
            <div>
                Pokud od nás nic neobdržíte do 3 dní, kontaktujte nás.
            </div>
            <div>
                Je nám ctí s Vámi spolupracovat.
            </div>
            <Stack horizontal horizontalAlign="end">
                <strong>Team Fresenius Kabi s.r.o.</strong>
            </Stack>
        </Stack>
    )
}

export default Finish;
