import { TextField, PrimaryButton, Stack, DefaultButton, Link } from "@fluentui/react";
import { useMainState } from "../globalState/context";
import { SET_SCREEN } from "../globalState/actions";

const ContactForm = () => {
    const { state: { contact, contract }, dispatch } = useMainState();
    const previousStep = () => dispatch({ type: SET_SCREEN, screen: "contract" });
    const nextStep = () => dispatch({ type: SET_SCREEN, screen: "summary" });

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <div>
                Zkontrolujte, prosím, údaje, které se Vás týkají. Pokud formulář obsahuje nesprávné údaje, informujte nás e-mailem
                na <Link href={`mailto:${process.env.REACT_APP_EMAIL}`}>{process.env.REACT_APP_EMAIL}</Link> a
                napište nám Vaše správné údaje, abychom je ve Smlouvě opravili. O opravě návrhu Smlouvy Vás budeme informovat e-mailem.
            </div>

            <Stack horizontal horizontalAlign="stretch" grow tokens={{ childrenGap: 10 }}>
                <TextField label="Jméno" defaultValue={contact?.firstName} disabled className="half" />
                <TextField label="Příjmení" defaultValue={contact?.lastName} disabled className="half" />
            </Stack>

            <Stack horizontal horizontalAlign="stretch" grow tokens={{ childrenGap: 10 }}>
                <TextField label="Specializace" defaultValue={contact?.specialization} disabled className="half" />
                <TextField label="Zdravotnické povolání" defaultValue={contact?.profession} disabled className="half" />
            </Stack>

            <Stack horizontal horizontalAlign="stretch" grow tokens={{ childrenGap: 10 }}>
                <TextField label="Zaměstnavatel" defaultValue={contact?.employer} disabled className="half" />
                <TextField label="Pracoviště" defaultValue={contact?.department} disabled className="half" />
            </Stack>

            {contact?.ico || contract?.invoice ?
                <>
                    <TextField label="IČO" defaultValue={contact?.ico} disabled className="half" />
                    <TextField label="Místo podnikání" defaultValue={contact?.placeOfBusiness} disabled />
                </>
                :
                <>
                    <TextField label="Datum narození" defaultValue={new Date(contact?.birthdate ?? new Date()).toLocaleDateString()} disabled className="half" />
                    <TextField label="Trvalé bydliště" defaultValue={contact?.permanentResidence} disabled />
                </>
            }

            <div>
                Pokud jsou údaje nesprávné dejte nám o tom vědět a zavřete okno prohlížeče (nepokračujte v procesu podpisu Smlouvy).
                Kliknutím na tlačítko POKRAČOVAT potvrdíte správnost Vašich osobních údajů, jak jsou uvedené výše. Abychom předešli
                jakékoli chybě, prosíme, abyste před kliknutím na tlačítko POKRAČOVAT znovu zkontrolovali správnost zadaných údajů.
            </div>

            <Stack horizontal verticalAlign="center" horizontalAlign="end" tokens={{ childrenGap: 10 }}>
                <DefaultButton text="Zpět" onClick={previousStep} />
                <PrimaryButton text="Pokračovat" onClick={nextStep} />
            </Stack>
        </Stack>
    )
}

export default ContactForm;
